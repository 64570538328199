html {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
}

body, h1, h2, h3, h4, h5, h6 {
  font-size: 15px;
  margin: 0;
  line-height: 24px;
}

body {
  margin: 0;
  //background-color: #efefef;
  //background: linear-gradient(#000,#0ecbc6);
  //background-image: url("images/Background.jpg");
}

body, html, #app, #app > div{
  height: 100%;
}

a {
  // color: #ff4081;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.m-b-15 {
  margin-bottom: 15px
}
